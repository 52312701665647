<template>
  <div class="user-container">
    <!-- 头部 -->
    <t-header />
    <!-- 头像 -->
    <img src="../assets/images/user_back.png" alt="" class="user-back" v-show="isChange" @click="goBack">
    <div class="user-info user-index" v-show="isChange">
      <div class="avatar-left">
        <img :src="user.avatar" alt="" v-if="user.avatar" />
        <div class="change-avatar" @click="changeAvatar">更换头像</div>
      </div>
      <div class="avatar-right">
        <div class="nick-name">
          <span>昵称</span>
          <el-input v-model="user.name" placeholder="输入昵称" class="nick-txt" maxlength="15" @change="updateUser('name',user.name)"></el-input>
          <!-- <input type="text" :value="user.name" class="nick-txt"> -->
        </div>
        <div class="sex">
          <span>性别</span>
          <div class="sex-both">
            <div class="sex-man" :class="user.gender==1?'active':''" @click="updateUser('gender',1)">男</div>
            <div class="sex-man sex-women" :class="user.gender==2?'active':''" @click="updateUser('gender',2)">女</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 更换头像 -->
    <div class="user-info change-avatar" v-show="!isChange">
      <div class="avatar-left">
        <img :src="avatar.avatar ? avatar.avatar :user.avatar" />
        <div class="change-avatar" @click="updateUserAvatar">确认</div>
      </div>
      <div ref="wrapper" class="wrapper">
        <ul class="avatar-bottom cont" ref="cont">
          <li v-for="item in avatarData" :key="item.avatar_id" :class="avatar.avatar_id==item.avatar_id?'active':''" @click="selectAvatar(item)">
            <img v-lazy="`https://app.metaio.cc/files/${item.images.owned_file_uuid}`" alt="头像">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import tHeader from "@/components/tHeader/index.vue";
import { getUserDetail, getAvatasList, updateUser } from '@/api/api'
import BScroll from 'better-scroll'
export default {
  name: "user",
  components: {
    tHeader,
  },
  data() {
    return {
      user: {},
      isChange: true,
      num: 1,
      avatarData: [],
      paginate: 150,
      avatar: {},
      scroll:null
    }
  },
  methods: {
    verScroll() {
      // let wrapper = this.$refs.wrapper
      // let scroll = new BScroll(wrapper,{scrollX: true,scrollY: false,})

      let width = this.avatarData.length * 200 // 动态计算出滚动区域的大小，前面已经说过了，产生滚动的原因是滚动区域宽度大于父盒子宽度
      this.$refs.cont.style.width = width + 'px' // 修改滚动区域的宽度
      this.$nextTick(() => {
        if (!this.scroll) {
          console.log(this.scroll)
          this.scroll = new BScroll(this.$refs.wrapper, {
            startX: -500, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
            click: true,
            scrollX: true,
            scrollY: false,
            bounce:true
          })
        } else {
          // this.scroll.refresh() //如果dom结构发生改变调用该方法
        }
      })
    },
    // 更换头像
    changeAvatar() {
      this.isChange = false
      let timer = setTimeout(() => {
      if (timer) {
        clearTimeout(timer)
        this.verScroll()
      }
    }, 0)
    },
    goBack() {
      this.$router.push({ path: '/square' })
      // this.$router.go(-1)
    },
    updateUser(type, data) {
      // this.user[type] = data
      const params = {
        [type]: data
      }
      updateUser(params).then(res => {
        if (res.code == 200) {
          this.getUserDetail()
          this.$store.dispatch('user/getInfo')
        }
      })
    },
    selectAvatar(item) {
      item.avatar = 'https://app.metaio.cc/files/' + item.images.owned_file_uuid
      this.avatar = item
    },
    updateUserAvatar() {
      if (!this.avatar.avatar_id) {
        return this.isChange = true
      }
      this.isChange = true
      const params = {
        'avatar': 'https://app.metaio.cc/files/' + this.avatar.images.owned_file_uuid,
        'avatar_id': this.avatar.avatar_id,
        'avatar_sid': this.avatar.avatar_listing_sid,
      }
      updateUser(params).then(res => {
        if (res.code == 200) {
          this.getUserDetail()
          this.$store.dispatch('user/getInfo')
        }
      })
    },
    getUserDetail() {
      getUserDetail().then(res => {
        if (res.code == 200) {
          this.user = res.data
        }
      })
    },
    getAvatasList() {
      const params = {
        paginate: this.paginate
      }
      getAvatasList(params).then(res => {
        if (res.code == 200) {
          this.avatarData = res.data.data
        }

      })
    },
  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.page = query.page ? query.page - 0 : 1
      this.getUserDetail()
      this.getAvatasList()
    })
  }
}

</script>
<style scoped lang="scss">
@import '../assets/css/user.scss';

::v-deep .el-input__inner {
  border: none;
  height: 100%;
}
.wrapper{
  width: 1800px;
  overflow: hidden;
}
</style>
